import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useNavigate } from "react-router-dom";
import { logoutUser } from "../store/slices/authSlice";
import { setAuthToken } from "../utils";
import mainLogo from "../assets/new-main-logo.png";

const Navbar = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { isAuthenticated, user } = useSelector((state) => state.auth);

	const handleLogout = () => {
		localStorage.removeItem("jwtToken");
		// remove auth header for future requests
		setAuthToken(false);
		dispatch(logoutUser());
		navigate("/");
	};

	return (
		<nav className="navbar fixed-top navbar-expand-lg navbar-light bg-light border-bottom">
			<div className="container">
				<NavLink className="navbar-brand mb-0" aria-current="page" to="/">
					<img src={mainLogo} alt="ParkMate" width="140" />
				</NavLink>

				<button
					className="navbar-toggler"
					type="button"
					data-bs-toggle="collapse"
					data-bs-target="#navbarTogglerDemo01"
					aria-controls="navbarTogglerDemo01"
					aria-expanded="false"
					aria-label="Toggle navigation"
				>
					<span className="navbar-toggler-icon"></span>
				</button>

				<div className="collapse navbar-collapse" id="navbarTogglerDemo01">
					<ul className="navbar-nav ms-auto mb-2 mb-lg-0">
						<li className="nav-item mb-3 mb-lg-0 me-lg-3">
							<NavLink
								className="nav-link text-decoration-none"
								aria-current="page"
								to="/home"
							>
								Home
							</NavLink>
						</li>
						<li className="nav-item">
							{isAuthenticated && (
								<button
									onClick={handleLogout}
									className="btn btn-primary"
								>
									Logout
								</button>
							)}
						</li>
					</ul>
				</div>
			</div>
		</nav>
	);
};

export default Navbar;
