import { createSlice } from "@reduxjs/toolkit";

export const authSlice = createSlice({
	name: "user",
	initialState: {
		user: null,
		isAuthenticated: false,
	},
	reducers: {
		loginUser: (state, action) => {
			state.user = action.payload;
			state.isAuthenticated = true;
		},
		logoutUser: (state) => {
			state.user = {};
			state.isAuthenticated = false;
		},
	},
});

// each case under reducers becomes an action
export const { loginUser, logoutUser } = authSlice.actions;

export default authSlice.reducer;
