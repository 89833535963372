import { useState, useEffect, useMemo } from "react";
import axios from "axios";
import { API_URL } from "./constants";
import BookingsList from "./components/BookingsList";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

export default function Home() {
   const { user } = useSelector((state) => state.auth);
   const BookingTypes = useMemo(
      () => ({
         phone: "phone",
         ticket: "ticket",
      }),
      []
   );
   const [bookingType, setBookingType] = useState(
      localStorage.getItem("bookingType") || BookingTypes.phone
   );
   const [values, setValues] = useState({
      firstname: "",
      lastname: "",
      usernumber: "",
      ticketNumber: "",
      valetnumber: "",
      userLocation: "",
      address: user.address,
      area: user.alias,
      carImage: null,
      ticketImage: null,
      carNumber: "",
      parkingInfo: "",
      parkingInfoOptions: user.parkingInfoOptions || null,
      paymentStatus: false,
      paymentType: "ONLINE",
   });
   const [bookingsRefresh, setBookingsRefresh] = useState(false);

   const [coords, setCoords] = useState({
      lat: 0,
      long: 0,
   });

   const [isSubmitting, setIsSubmitting] = useState(false);
   const [valetsList, setValetsList] = useState([]);

   const fillCoords = () => {
      const options = {
         enableHighAccuracy: true,
         timeout: 5000,
         maximumAge: 0,
      };

      function success(pos) {
         const crd = pos.coords;

         setCoords({
            lat: crd.latitude,
            long: crd.longitude,
         });
      }

      function error(err) {
         console.warn(`ERROR(${err.code}): ${err.message}`);
         toast.error("Failed to get Coordinates, Please Try Again");
      }

      navigator.geolocation.getCurrentPosition(success, error, options);
   };

   const handleChange = (e) => {
      setValues({
         ...values,
         [e.target.name]: e.target.value,
      });
   };

   const handlePaymentTypeChange = (e) => {
      const paymentSelected = e.target.value;
      if (paymentSelected !== "postpaid") {
         setValues({
            ...values,
            paymentType: paymentSelected,
            paymentStatus: true,
         });
      } else {
         setValues({
            ...values,
            paymentType: "ONLINE",
            paymentStatus: false,
         });
      }
   };

   async function fetchValets() {
      const res = await axios.get(`${API_URL}/api/valets`, {
         params: {
            area: user.alias,
         },
      });

      console.log(res.data);
      setValetsList(res.data.valets);
   }

   const renderOptions = () => {
      const options = values.parkingInfoOptions;
      if (options) {
         const buttons = options.map((value) => (
            <button
               onClick={() => setValues({ ...values, parkingInfo: value })}
               className="btn btn-sm btn-light border me-2"
               type="button"
            >
               {value}
            </button>
         ));

         return <div className="mt-2">{buttons}</div>;
      }

      return "";
   };

   useEffect(() => {
      // if (coords.lat && coords.long) {
      fillCoords();
      fetchValets();
      // }
   }, []);

   const handleSubmit = async (e) => {
      e.preventDefault();

      if (!coords.lat || !coords.long) {
         toast.error("Coordinates not available");
      } else {
         console.table({
            ...values,
            ...coords,
         });

         setIsSubmitting(true);

         const fullName = values.firstname;

         const nameParts = fullName.split(" ");

         let firstname = "";
         let lastname = "";

         if (nameParts.length === 1) {
            firstname = nameParts[0];
         } else if (nameParts.length === 2) {
            firstname =
               nameParts[0].charAt(0).toUpperCase() + nameParts[0].slice(1);
            lastname = nameParts[1];
         } else {
            firstname =
               nameParts[0].charAt(0).toUpperCase() + nameParts[0].slice(1);
            lastname = nameParts.slice(1).join(" ");
         }

         if (!firstname) {
            firstname = "Guest";
         }

         try {
            // register user
            const userData = {
               firstname,
               lastname,
               phonenumber: `+91${values.usernumber}`,
               pickupCoords: coords,
            };

            const userResponse = await axios.post(
               `${API_URL}/api/admins/register/users`,
               userData
            );
            const newUser = userResponse.data.user;

            console.log({ newUser });

            const formData = new FormData();

            formData.append("userId", newUser._id);
            formData.append("pickupAddress", values.address);
            formData.append("valetNumber", `+91${values.valetnumber}`);
            formData.append("userLocation", values.userLocation);
            formData.append("pickupCoords", coords);
            formData.append("lat", coords.lat);
            formData.append("long", coords.long);
            formData.append("area", values.area);
            formData.append("carImage", values.carImage);
            formData.append("ticketImage", values.ticketImage);
            formData.append("vehicleNumber", values.vehicleNumber);
            formData.append("parkingInfo", values.parkingInfo);
            formData.append("paymentType", values.paymentType);
            formData.append("paymentStatus", values.paymentStatus);

            const bookingResponse = await axios.post(
               `${API_URL}/api/callBookings/register`,
               formData
            );

            const x = bookingResponse.data;

            console.log({ data: x });

            setValues({
               ...values,
               valetnumber: "",
               usernumber: "",
               carImage: null,
               ticketImage: null,
               vehicleNumber: "",
               userLocation: "",
               firstname: "Guest",
               paymentStatus: false,
               paymentType: "ONLINE",
            });

            console.log("YII", x.newOrder.keyNumber);

            const toastData = (
               <div className="d-flex flex-column justify-content-center align-items-start">
                  <p
                     className="mb-0"
                     style={{
                        fontWeight: 500,
                        fontSize: "0.6rem",
                        letterSpacing: 1.1,
                     }}
                  >
                     BOOKING SUCCESSFUL
                  </p>
                  <p
                     className="mb-0 mt-1"
                     style={{
                        fontWeight: 600,
                        fontSize: "1rem",
                        letterSpacing: 1.1,
                     }}
                  >
                     KEY NO. {x.newOrder.keyNumber}
                  </p>
               </div>
            );

            toast.success(toastData, {
               position: "top-right",
               autoClose: 5000,
               hideProgressBar: false,
               closeOnClick: true,
               pauseOnHover: false,
               draggable: true,
               progress: undefined,
            });
            setIsSubmitting(false);
            setBookingsRefresh((prev) => !prev);
         } catch (err) {
            if (
               err.response &&
               err.response.data &&
               err.response.data.error &&
               Object.keys(err.response.data.error).length
            ) {
               console.log({ errorBoi: err.response });
               toast.error(
                  err.response.data.error || err.response.data.message
               );
            } else {
               console.log(err.response);
               toast.error("Some error occured, try again");
            }
            setIsSubmitting(false);
         }
      }
   };

   const handleTicketSubmit = async (e) => {
      e.preventDefault();

      if (!coords.lat || !coords.long) {
         toast.error("Coordinates not available");
      } else {
         console.table({
            ...values,
            ...coords,
         });

         setIsSubmitting(true);

         try {
            // register booking
            const bookingData = {
               ticketNumber: `${user.alias}${values.ticketNumber}`,
               pickupAddress: values.address,
               valetNumber: `+91${values.valetnumber}`,
               lat: coords.lat,
               long: coords.long,
               area: values.area,
               vehicleNumber: values.vehicleNumber,
            };

            const bookingResponse = await axios.post(
               `${API_URL}/api/ticket-bookings/`,
               bookingData
            );

            const x = bookingResponse.data;

            console.log({ data: x });

            setValues({
               ...values,
               valetnumber: "",
               ticketNumber: "",
               carImage: null,
               ticketImage: null,
               vehicleNumber: "",
            });

            toast.success("Request Submitted Successfully");
            setIsSubmitting(false);
            setBookingsRefresh((prev) => !prev);
         } catch (err) {
            if (
               err.response &&
               err.response.data &&
               err.response.data.error &&
               Object.keys(err.response.data.error).length
            ) {
               console.log({ errorBoi: err.response });
               toast.error(
                  err.response.data.error || err.response.data.message
               );
            } else {
               console.log(err.response);
               toast.error("Some error occured, try again");
            }
            setIsSubmitting(false);
         }
      }
   };

   return (
      <div>
         <div className="container">
            <div className="d-flex align-items-center flex-column">
               <img
                  src={user.brandImage}
                  alt={user.fullname}
                  className="partners-logo"
               />
               <p className="text-center">Booking Panel</p>
            </div>
            <ul
               className="nav nav-tabs justify-content-center mt-4"
               style={{ maxWidth: 500, width: "95%", margin: "20px auto 0" }}
            >
               <li className="nav-item">
                  <button
                     onClick={() => {
                        setBookingType(BookingTypes.phone);
                        localStorage.setItem("bookingType", BookingTypes.phone);
                     }}
                     className={`nav-link btn-sm  ${
                        bookingType === BookingTypes.phone
                           ? "text-dark active"
                           : "text-secondary"
                     }`}
                     style={{
                        fontWeight:
                           bookingType === BookingTypes.phone ? 500 : 400,
                     }}
                  >
                     Phone No. Booking
                  </button>
               </li>
               <li className="nav-item">
                  <button
                     onClick={() => {
                        setBookingType(BookingTypes.ticket);
                        localStorage.setItem(
                           "bookingType",
                           BookingTypes.ticket
                        );
                     }}
                     className={`nav-link btn-sm  ${
                        bookingType === BookingTypes.ticket
                           ? "text-dark active"
                           : "text-secondary"
                     }`}
                     style={{
                        fontWeight:
                           bookingType === BookingTypes.ticket ? 500 : 400,
                     }}
                  >
                     QR Based Booking
                  </button>
               </li>
            </ul>
            <form
               onSubmit={
                  bookingType === BookingTypes.phone
                     ? handleSubmit
                     : handleTicketSubmit
               }
               style={{ maxWidth: 500, width: "95%", margin: "20px auto 0" }}
            >
               {bookingType === BookingTypes.phone && (
                  <div className="form-group my-4">
                     <div className="input-group">
                        <input
                           name="usernumber"
                           value={values.usernumber}
                           onChange={handleChange}
                           type="number"
                           required
                           className="form-control"
                           id="usernumber"
                           placeholder="User Phone Number"
                        />
                        <button
                           onClick={() =>
                              setValues({ ...values, usernumber: "" })
                           }
                           className="btn btn-sm btn-light border"
                           type="button"
                        >
                           Clear
                        </button>
                     </div>
                  </div>
               )}

               {bookingType === BookingTypes.phone && user.alias === "PXCD" && (
                  <div className="d-flex">
                     <div className="form-group">
                        <label htmlFor="parkingInfo" className="form-label">
                           Name
                        </label>
                        <div className="input-group">
                           <input
                              name="firstname"
                              value={values.firstname}
                              onChange={(e) => {
                                 handleChange(e);
                              }}
                              type="text"
                              className="form-control"
                              id="firstname"
                              placeholder="Name"
                           />
                           <button
                              onClick={() =>
                                 setValues({ ...values, firstname: "" })
                              }
                              className="btn btn-sm btn-light border"
                              type="button"
                           >
                              Clear
                           </button>
                        </div>
                     </div>
                     <div className="form-group ps-3">
                        <label htmlFor="userLocation" className="form-label">
                           Location
                        </label>
                        <div className="input-group">
                           <input
                              name="userLocation"
                              value={values.userLocation}
                              onChange={(e) => {
                                 handleChange(e);
                              }}
                              type="text"
                              className="form-control"
                              id="userLocation"
                              placeholder="Location"
                           />
                           <button
                              onClick={() =>
                                 setValues({ ...values, userLocation: "" })
                              }
                              className="btn btn-sm btn-light border"
                              type="button"
                           >
                              Clear
                           </button>
                        </div>
                     </div>
                  </div>
               )}

               {bookingType === BookingTypes.ticket && (
                  <div className="form-group my-4">
                     <div className="input-group">
                        <span class="input-group-text" id="basic-addon1">
                           {user.alias}
                        </span>

                        <input
                           name="ticketNumber"
                           value={values.ticketNumber}
                           onChange={handleChange}
                           type="text"
                           required
                           className="form-control"
                           id="ticketNumber"
                           placeholder="Ticket Number"
                        />
                        <button
                           onClick={() =>
                              setValues({ ...values, ticketNumber: "" })
                           }
                           className="btn btn-sm btn-light border"
                           type="button"
                        >
                           Clear
                        </button>
                     </div>
                  </div>
               )}

               <div className="form-group mt-4 mb-2">
                  <div className="input-group">
                     <input
                        name="valetnumber"
                        value={values.valetnumber}
                        onChange={handleChange}
                        type="number"
                        className="form-control"
                        id="valetnumber"
                        placeholder="Valet Phone Number"
                     />
                     <button
                        onClick={() =>
                           setValues({ ...values, valetnumber: "" })
                        }
                        className="btn btn-sm btn-light border"
                        type="button"
                     >
                        Clear
                     </button>
                  </div>
               </div>
               <div className="">
                  <a
                     className={"btn btn-sm show-valets-btn btn-warning"}
                     data-bs-toggle="offcanvas"
                     href="#offcanvasExample"
                     role="button"
                     aria-controls="offcanvasExample"
                  >
                     Show Valets
                  </a>
               </div>
               <div class="mt-3">
                  <p className="mb-2">Payment Options</p>
                  <div class="form-check form-check-inline">
                     <input
                        class="form-check-input"
                        type="radio"
                        name="paymentType"
                        id="postpaid"
                        value="postpaid"
                        checked={!values.paymentStatus}
                        onChange={handlePaymentTypeChange}
                     />
                     <label class="form-check-label" for="postpaid">
                        Postpaid
                     </label>
                  </div>
                  <div class="form-check form-check-inline">
                     <input
                        class="form-check-input"
                        type="radio"
                        name="paymentType"
                        id="upi"
                        value="ONLINE"
                        checked={
                           values.paymentStatus &&
                           values.paymentType === "ONLINE"
                        }
                        onChange={handlePaymentTypeChange}
                     />
                     <label class="form-check-label" for="upi">
                        UPI - Prepaid
                     </label>
                  </div>
                  <div class="form-check form-check-inline">
                     <input
                        class="form-check-input"
                        type="radio"
                        name="paymentType"
                        id="cash"
                        value="OFFLINE"
                        checked={
                           values.paymentStatus &&
                           values.paymentType === "OFFLINE"
                        }
                        onChange={handlePaymentTypeChange}
                     />
                     <label class="form-check-label" for="cash">
                        Cash - Prepaid
                     </label>
                  </div>
               </div>
               <div className="form-group my-4">
                  <label htmlFor="vehicleNumber" className="form-label">
                     Vehicle Number
                  </label>
                  <div className="input-group">
                     <input
                        name="vehicleNumber"
                        value={values.vehicleNumber}
                        onChange={(e) => {
                           e.target.value = e.target.value.toUpperCase();
                           handleChange(e);
                        }}
                        type="text"
                        className="form-control"
                        id="vehicleNumber"
                        placeholder="UK08AB1234 (optional)"
                     />
                     <button
                        onClick={() =>
                           setValues({ ...values, vehicleNumber: "" })
                        }
                        className="btn btn-sm btn-light border"
                        type="button"
                     >
                        Clear
                     </button>
                  </div>
               </div>
               <div className="form-group my-4">
                  <label htmlFor="parkingInfo" className="form-label">
                     Parking Info
                  </label>
                  <div className="input-group">
                     <input
                        name="parkingInfo"
                        value={values.parkingInfo}
                        onChange={(e) => {
                           e.target.value = e.target.value.toUpperCase();
                           handleChange(e);
                        }}
                        type="text"
                        className="form-control"
                        id="parkingInfo"
                        placeholder="Parking info here"
                     />
                     <button
                        onClick={() =>
                           setValues({ ...values, parkingInfo: "" })
                        }
                        className="btn btn-sm btn-light border"
                        type="button"
                     >
                        Clear
                     </button>
                  </div>
                  {renderOptions()}
               </div>
               <div className="input-group my-4">
                  <input
                     name="coords"
                     required
                     type="text"
                     value={
                        coords.lat && coords.long
                           ? `${coords.lat}, ${coords.long}`
                           : "Click Fill coodinates"
                     }
                     className="form-control"
                     id="coords"
                     disabled
                     placeholder="Coordinates"
                  />
                  <button
                     type="button"
                     className="btn btn-success btn-sm"
                     onClick={fillCoords}
                  >
                     Fill Coordinates
                  </button>
               </div>
               <button
                  type="submit"
                  disabled={isSubmitting}
                  className="btn btn-primary mt-2 px-4"
               >
                  {isSubmitting ? (
                     <>
                        <span
                           className="spinner-border spinner-border-sm me-2"
                           role="status"
                           aria-hidden="true"
                        ></span>
                        <span>Submitting...</span>
                     </>
                  ) : (
                     <span>Submit</span>
                  )}
               </button>
            </form>
         </div>

         <hr className="mt-5 mb-4" />

         <BookingsList bookingsRefresh={bookingsRefresh} />

         <div
            className="offcanvas offcanvas-start"
            tabIndex="-1"
            id="offcanvasExample"
            aria-labelledby="offcanvasExampleLabel"
         >
            <div className="offcanvas-header">
               <h5 className="offcanvas-title" id="offcanvasExampleLabel">
                  Valets List
               </h5>
               <button
                  type="button"
                  className="btn-close text-reset hide-valets-btn"
                  data-bs-dismiss="offcanvas"
                  aria-label="Close"
               ></button>
            </div>
            <div className="offcanvas-body">
               <ul className="list-group">
                  {valetsList.map((v) => (
                     <li
                        key={v._id}
                        className="list-group-item cursor-pointer list-group-item-action"
                        onClick={() => {
                           setValues({
                              ...values,
                              valetnumber: v.phonenumber.slice(3),
                           });
                           document.querySelector(".hide-valets-btn").click();
                        }}
                     >
                        {v.firstname + " " + v.lastname} {v.phonenumber}
                     </li>
                  ))}
               </ul>
            </div>
         </div>
      </div>
   );
}
