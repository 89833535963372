import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import axios from "axios";
import { API_URL } from "./constants";
import { loginUser } from "./store/slices/authSlice";
import { setAuthToken } from "./utils";
import { toast } from "react-toastify";

export default function Login() {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const [formValues, setFormValues] = useState({
		username: "",
		password: "",
	});
	const [isSubmitting, setIsSubmitting] = useState(false);

	const handleChange = (e) => {
		setFormValues({
			...formValues,
			[e.target.name]: e.target.value,
		});
	};

	const handleSubmit = async (e) => {
		e.preventDefault();
		try {
			setIsSubmitting(true);
			const response = await axios.post(
				`${API_URL}/api/partners/login`,
				{
					alias: formValues.username,
					password: formValues.password,
				},
				{
					params: {
						type: "booking",
					},
				}
			);

			const data = await response.data;

			localStorage.setItem("jwtToken", data.accessToken);
			setAuthToken(data.accessToken);

			dispatch(loginUser(data.user));

			toast.success("Logged in successfully");
			setIsSubmitting(false);

			navigate("/home");
		} catch (error) {
			console.log({ error: error.response });
			if (error.response && error.response.data) {
				toast.error(error.response.data.error);
			} else {
				toast.error(error);
			}
			setIsSubmitting(false);
		}
	};

	return (
		<div>
			<div className="container">
				<h3 className="mt-4 text-center">Companion Booking Panel</h3>
				<form
					onSubmit={handleSubmit}
					style={{ maxWidth: 500, width: "95%", margin: "40px auto 0" }}
				>
					<div className="form-group mb-3">
						<label htmlFor="exampleInputPassword1" className="mb-2">
							Username
						</label>
						<input
							type="text"
							className="form-control"
							id="username"
							name="username"
							placeholder="Username"
							value={formValues.username}
							onChange={handleChange}
						/>
					</div>
					<div className="form-group">
						<label htmlFor="exampleInputPassword1" className="mb-2">
							Password
						</label>
						<input
							type="password"
							className="form-control"
							id="password"
							name="password"
							placeholder="Password"
							value={formValues.password}
							onChange={handleChange}
						/>
					</div>
					<button type="submit" disabled={isSubmitting} className="btn btn-primary mt-4">
						{isSubmitting ? (
							<>
								<span
									className="spinner-border spinner-border-sm me-2"
									role="status"
									aria-hidden="true"
								></span>
								<span>Submitting...</span>
							</>
						) : (
							<span>Submit</span>
						)}
					</button>
				</form>
			</div>
		</div>
	);
}
