import { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useNavigate,
} from "react-router-dom";
import { Provider, useDispatch } from "react-redux";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Login from "./Login";
import Home from "./Home";
import "./App.css";
import BookingDetails from "./BookingDetails";
import store from "./store";
import PrivateRoute from "./components/PrivateRoute";
import { setAuthToken } from "./utils";
import jwt_decode from "jwt-decode";
import { loginUser, logoutUser } from "./store/slices/authSlice";
import SplashScreen from "./components/SplashScreen";
import Layout from "./components/Layout";
import axios from "axios";
import { API_URL } from "./constants";

function App() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [timer, setTimer] = useState(null);

  useEffect(() => {
    const handleUserLogin = async (userId) => {
      try {
        const response = await axios.get(`${API_URL}/api/partners/${userId}`);
        const data = await response.data;
        dispatch(loginUser(data.user));
        navigate("/home");

        clearTimeout(timer);
        setTimer(null);
        const t = setTimeout(() => {
          setIsLoading(false);
        }, 1000);
        setTimer(t);
      } catch (error) {
        // localStorage.removeItem("jwtToken");
        // setAuthToken(null);
        // dispatch(logoutUser());
        if (error.response && error.response.data) {
          toast.error(error.response.data.error);
        } else {
          toast.error(error);
        }
        setIsLoading(false);
      }
    };

    if (localStorage.jwtToken) {
      setIsLoading(true);
      const token = localStorage.jwtToken;
      setAuthToken(token);

      const decoded = jwt_decode(token);

      const currentTime = Date.now() / 1000;
      if (decoded.exp < currentTime) {
        localStorage.removeItem("jwtToken");
        setAuthToken(null);
        dispatch(logoutUser());

        navigate("/");
        setIsLoading(false);
      } else {
        handleUserLogin(decoded._id);
      }
    } else {
      setIsLoading(false);
    }

    return () => {
      clearTimeout(timer);
    };
  }, []);

  if (isLoading) return <SplashScreen />;

  return (
    <Layout>
      <Routes>
        <Route exact path="/" element={<Login />} />
        <Route
          path={`/home`}
          element={
            <PrivateRoute>
              <Home />
            </PrivateRoute>
          }
        />
        <Route
          path="/bookingDetail/:id"
          element={
            <PrivateRoute>
              <BookingDetails />
            </PrivateRoute>
          }
        />
      </Routes>
    </Layout>
  );
}

function Main() {
  return (
    <Provider store={store}>
      <ToastContainer />
      <Router>
        <App />
      </Router>
    </Provider>
  );
}

export default Main;
