import React, { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { format } from "date-fns";
import { BsThreeDotsVertical, BsFillCheckCircleFill } from "react-icons/bs";
import { FaRupeeSign, FaUser, FaCar, FaParking } from "react-icons/fa";
// import { TbPencilMinus } from "react-icons/tb";
import { HiOutlinePencil, HiPlusSm } from "react-icons/hi";
import { RiVipCrownFill } from "react-icons/ri";
import { MdCancel, MdBlock } from "react-icons/md";
import { QRCodeSVG } from "qrcode.react";
import newMainLogo from "../assets/new-main-logo.png";

const COLORS = {
   blue: "#3b82f6",
   green: "#10b981",
   orange: "#fb923c",
   red: "#ef4444",
   purple: "#7c3aed",
   pink: "#f472b6",
   gray: "#525252",
};

const BookingCard = ({ booking, confirmBookingActions }) => {
   const navigate = useNavigate();

   const getSelectedValet = (booking) => {
      return "";
      // if (booking.currentType === "PICKUP") {
      //   if (booking.pickupValet && Object.keys(booking.pickupValet).length) {
      //     return booking.pickupValet.firstname;
      //   } else {
      //     return booking.selectedValets[0];
      //   }
      // } else if (booking.currentType === "DELIVERY") {
      //   if (booking.deliveryValet && Object.keys(booking.deliveryValet).length) {
      //     return booking.deliveryValet.firstname;
      //   } else {
      //     return booking.selectedValets[0];
      //   }
      // }
   };

   //-------PERMANENT INFO-----------------
   /*
      Booking ID - required
      Key Number - required

      Automatic Status  / Marked Status - required
      Customer Name , Number  - required
      Booking Time and Date  - required
      Payment Status - Paid , not paid , exempted - required
      
      Parking Location Info - optional
      Car Number - optional

      Three DOT Options - Payment Done , Exempt ,MARK  Complete ,Mark Cancelled
   */

   //-------STATUS BASED INFO-----------------
   /*
      PICKUP ===================== Blue 
        PENDING - request sent to , change
        ACCEPTED_PICKUP - pickup valet, change 
        VALET_ARRIVED - pickup valet
        PICKED_UP - pickup valet 

      AT PICKUP ================= orange 
        AT_PARKING - pickup valet 

      DELIVERY ================= Purple  
        PENDING - request sent , change 
        ACCEPTED_DELIVERY - delivery valet , change 
        DELIVERING - delivery valet 
        AT_CUSTOMER - delivery valet 
        CUSTOMER_ARRIVED - delivery valet
        DELIVERED - delivery valet
 
      COMPLETED =============== GREEN 
        COMPLETED - Pickup and deliery valet

      CANCELLED =========== RED 
        CANCELLED - 
          pickup valet - if available else , pickup notif sent to
          delivery valet - if available else , delivery notif sent to
   */

   const renderBookingStatus = (type, status, markedAs) => {
      console.log("Booking status");

      /**
       * 1. check markedAs = return COMPLETED or CANCELLED , return
       * 2. COLOR , according to AT_PARKING , COMPLETED , CANCELLED else booking type = PICKUP , DELIVERY
       * 3. pending status extra check for booking type, else normal status text
       */

      /**
       *  pickup = Blue
       *  atparking = orange
       *  delivery = purple
       *  completed = green
       *  cancelled = red =
       */

      let text = "";
      let color = "";

      // if marked as set and return ======
      if (markedAs === "COMPLETED") {
         text = "MARKED COMPLETED";
         color = COLORS.green;

         return { text, color };
      } else if (markedAs === "CANCELLED") {
         text = "MARKED CANCELLED";
         color = COLORS.red;

         return { text, color };
      }

      // setting colors
      if (status === "AT_PARKING") {
         color = COLORS.uihu;
      } else if (status === "COMPLETED") {
         color = COLORS.green;
      } else if (status === "CANCELLED") {
         color = COLORS.red;
      } else if (type === "PICKUP") {
         color = COLORS.orange;
      } else if (type === "DELIVERY") {
         color = COLORS.purple;
      } else {
         color = COLORS.blue;
      }

      // setting text data
      if (status === "PENDING") {
         text = `${type} Pending`;
      } else {
         text = status.replace("_", " ");
      }

      text = text.toUpperCase();

      return { text, color };
   };

   const renderPaymentStatus = (type, status) => {
      console.log("Payment status");
      // 1. if type Exempted , exempted return
      // 2. payment status = false , not paid
      // 3. payment status = true , paid

      let text, color, icon;

      if (type === "EXEMPTED") {
         text = "EXEMPTED";
         color = COLORS.blue;
         icon = (
            <RiVipCrownFill
               style={{ fontSize: "0.8rem" }}
               color={COLORS.blue}
            />
         );
      } else if (status) {
         text = "PAID";
         color = COLORS.green;
         icon = (
            <FaRupeeSign style={{ fontSize: "0.8rem" }} color={COLORS.green} />
         );
      } else {
         text = "NOT PAID";
         color = COLORS.red;
         icon = <MdBlock style={{ fontSize: "0.8rem" }} color={COLORS.red} />;
      }

      return { text, color, icon };
   };

   const renderValetInfo = (
      type,
      status,
      markedAs,
      pickupSelectedValets,
      pickupValet,
      deliveryValet
   ) => {
      console.log("Valet Info");

      /**
       * 2. Completed/Cancelled/Marked -> Pickup and Delivery Valet
       * 3. Pending -> Pickup ->  Notifiation Sent to
       * 5. Pickup / Delivery -> Valet Info
       */

      /**
         type, // PICKUP / DELIVERY
         status, // ALL 12 Status
         markedAs // COMPLETED , CANCELLED , NONE
         pickupSelectedValets // array of valets who got pickup notif
         pickupValet,
         deliveryValet
       */

      if (
         markedAs === "COMPLETED" ||
         markedAs === "CANCELLED" ||
         status === "COMPLETED" ||
         status === "CANCELLED"
      ) {
         return (
            <>
               <p className="text-center mb-0" style={{ fontSize: "0.75rem" }}>
                  Pickup Valet
               </p>
               <p
                  className="mt-1 mb-2 fw-bolder"
                  style={{ fontSize: "0.7rem" }}
               >
                  {pickupValet.name}
               </p>
               <p className="text-center mb-0" style={{ fontSize: "0.75rem" }}>
                  Delivery Valet
               </p>
               <p
                  className="mt-1 mb-2 fw-bolder"
                  style={{ fontSize: "0.7rem" }}
               >
                  {deliveryValet.name}
               </p>
            </>
         );
      }

      const pickupSelectedValetList = [];

      for (var i = 0; i < pickupSelectedValets.length; i++) {
         if (i === 2) {
            pickupSelectedValetList.push(
               `${pickupSelectedValets.length - i} more`
            );
            break;
         }
         pickupSelectedValetList.push(pickupSelectedValets[i]);
      }

      if (status === "PENDING" && type === "PICKUP") {
         return (
            <>
               <p className="text-center mb-0" style={{ fontSize: "0.75rem" }}>
                  Pickup Sent
               </p>
               <ul className="mt-1 mb-2" style={{ fontSize: "0.7rem" }}>
                  {pickupSelectedValetList.map((valet) => (
                     <li>{valet}</li>
                  ))}
               </ul>
            </>
         );
      }

      if (status === "PENDING" && type === "DELIVERY") {
         return (
            <>
               <p className="text-end mb-0" style={{ fontSize: "0.75rem" }}>
                  Delivery Notification Sent
               </p>
            </>
         );
      }

      // THE Pickup/Delivery Valet info VERSION
      return (
         <>
            <p className="text-end mb-0" style={{ fontSize: "0.75rem" }}>
               {type} Valet
            </p>
            <p className="my-1 fw-bolder" style={{ fontSize: "1rem" }}>
               {type === "PICKUP" && pickupValet.name}
               {type === "DELIVERY" && deliveryValet.name}
            </p>
            <p className=" fw-bolder" style={{ fontSize: "1rem" }}>
               {type === "PICKUP" && pickupValet.phone}
               {type === "DELIVERY" && deliveryValet.phone}
            </p>
         </>
      );

      /*
      // THE FINAL VERSION
      return (
         <>
            <p className="text-end mb-0" style={{ fontSize: "0.75rem" }}>
               Pickup Valet
            </p>
            <img
               src={personImage}
               alt="Valet"
               className="mt-1"
               style={{
                  width: "2.5rem",
                  height: "2.5rem",
                  borderRadius: "100%",
                  objectFit: "cover",
               }}
            />
            <p className="mt-1 mb-2 fw-bolder" style={{ fontSize: "1rem" }}>
               Dhananjaya
            </p>
            <button
               type="button"
               // class="btn btn-primary py-1 px-2"
               class="btn badge rounded-pill bg-primary"
               style={{ fontSize: "0.7rem" }}
            >
               Change Valet
            </button>
         </>
      );
      */
   };

   const getCarNumber = (number1, number2) => {
      if (number1 !== "undefined" && number1) {
         return number1;
      } else if (number2 !== "undefined" && number2) {
         return number2;
      } else {
         return "--";
      }
   };

   const getSelectedValets = (selectedValets) => {
      if (selectedValets.length < 1) {
         return [];
      }
      const selected = selectedValets.map((valet) => valet.firstname);
      return selected;
   };

   const cardData = useMemo(() => {
      return {
         id: booking.bookingId,
         key: booking.keyNumber,
         bookingStatus: renderBookingStatus(
            booking.currentType, // PICKUP / DELIVERY
            booking.status, // ALL 12 Status
            booking.isMarkedAs // COMPLETED , CANCELLED , NONE
         ),
         paymentStatus: renderPaymentStatus(
            booking.paymentType, // ONLINE , OFFLINE , FREE , EXEMPTED
            booking.paymentStatus // true, false
         ),
         customerInfo: {
            name: booking.user?.firstname || "Guest",
            phone: booking.user?.phonenumber || "--",
         },
         createdTime: format(
            new Date(booking.createdAt),
            "hh:mm aa | dd-MM-yy"
         ),
         parkingInfo: booking.parkingInfo || "--",
         carNumber: getCarNumber(booking.vehicleId, booking.vehicleNumber),
         pickupSelectedValets: getSelectedValets(booking.selectedValets),
         pickupValetInfo: {
            name: booking.pickupValet?.firstname || "--",
            phone: booking.pickupValet?.phonenumber || "--",
         },
         deliveryValetInfo: {
            name: booking.deliveryValet?.firstname || "--",
            phone: booking.deliveryValet?.phonenumber || "--",
         },
      };
   }, [booking]);

   // useEffect(() => {
   //    console.log("LETS GOO", booking);
   //    console.log("CARD DATA", cardData);
   // }, [booking, cardData]);

   return (
      <div
         className="card"
         style={{ fontFamily: "Montserrat", border: "3px solid #DFDFDF" }}
      >
         {/* CARD HEADER */}
         <div
            className="card-header d-flex justify-content-between align-items-center"
            style={{ border: "3px solid #f6f6f6", background: "#f6f6f6" }}
         >
            <div className="d-flex justify-content-between align-items-center">
               <p
                  className="mb-0"
                  style={{ fontWeight: 500, fontSize: "0.75rem" }}
               >
                  ID: {cardData.id}
               </p>
               <button
                  className="mb-0 btn btn-link fw-bold p-0 ms-2 text-uppercase d-flex flex-row justify-content-center align-items-center"
                  style={{
                     color: COLORS.blue,
                     fontSize: "0.65rem",
                     // letterSpacing: "1.1px",
                     textDecoration: "underline",
                  }}
                  data-bs-toggle="modal"
                  data-bs-target={`#${cardData.id}-Modal`}
               >
                  Show QR
               </button>
               {/* {console.log("INSIDE OUT")} */}
               <div
                  class="modal fade"
                  id={`${cardData.id}-Modal`}
                  tabindex="-1"
                  aria-labelledby="exampleModalLabel"
                  aria-hidden="true"
               >
                  <div class="modal-dialog  modal-dialog-centered">
                     <div class="modal-content">
                        <div
                           class="modal-header border-none"
                           style={{ background: "#ffffff", border: "none" }}
                        >
                           <p
                              className="mb-0"
                              style={{
                                 fontWeight: 500,
                                 fontSize: "1.25rem",
                                 letterSpacing: 1.1,
                              }}
                           >
                              BOOKING QR
                           </p>
                           <button
                              type="button"
                              class="btn-close"
                              data-bs-dismiss="modal"
                              aria-label="Close"
                           ></button>
                        </div>
                        <div class="modal-body">
                           <div className="d-flex  align-items-center justify-content-between px-2">
                              <div className="customerInfo d-flex justify-content-center align-items-center">
                                 {booking?.bookingMethod === "TICKET_BOOKING" &&
                                 booking?.user?._id ===
                                    "61d3db83457f59000497cdcc" ? (
                                    <p className="fs-6 mb-0">
                                       Ticket:{" "}
                                       <span className="fs-6">
                                          {booking.ticketNumber}
                                       </span>
                                    </p>
                                 ) : (
                                    <>
                                       <FaUser style={{ fontSize: "1.1rem" }} />
                                       <p
                                          className="mb-0 ms-2"
                                          style={{ fontSize: "1.1rem" }}
                                       >
                                          {/* {cardData.customerInfo.name} */}
                                          <span
                                             className="mb-0"
                                             style={{ fontSize: "1.1rem" }}
                                          >
                                             {cardData.customerInfo.phone}
                                          </span>
                                       </p>
                                    </>
                                 )}
                              </div>
                              <div className="vehicleInfo d-flex justify-content-center align-items-center">
                                 <div className="vehicleInfo d-flex justify-content-center align-items-center">
                                    <p
                                       className="mb-0 me-2"
                                       style={{ fontSize: "1.1rem" }}
                                    >
                                       {cardData.carNumber}
                                    </p>
                                    <FaCar style={{ fontSize: "1.1rem" }} />
                                 </div>
                              </div>
                           </div>

                           <div className="d-flex flex-column mt-5 justify-content-center align-items-center">
                              <div className="d-flex justify-content-center align-items-center">
                                 <p
                                    className="mb-0 text-muted"
                                    style={{
                                       fontWeight: 500,
                                       fontSize: "0.8rem",
                                    }}
                                 >
                                    ID: {cardData.id} {" | "}
                                 </p>
                                 <p
                                    className="mb-0 text-muted ms-1"
                                    style={{
                                       fontWeight: 500,
                                       fontSize: "0.8rem",
                                    }}
                                 >
                                    OTP: {booking.webOTP}
                                 </p>
                              </div>
                              <QRCodeSVG
                                 value={`https://web.parkmate.in/${booking.shortId}?q=${booking.webOTP}`}
                                 className="my-3"
                                 size={200}
                              />

                              <p
                                 className="mb-0 mt-1"
                                 style={{ fontSize: "0.9rem" }}
                              >
                                 {cardData.createdTime}
                              </p>

                              <img
                                 src={newMainLogo}
                                 alt="ParkMate"
                                 width="180"
                                 className="mt-5 mb-2"
                              />
                           </div>
                        </div>
                        {/* <div class="modal-footer">
                           <button
                              type="button"
                              class="btn btn-secondary"
                              data-bs-dismiss="modal"
                           >
                              Close
                           </button>
                           <button type="button" class="btn btn-primary">
                              Save changes
                           </button>
                        </div> */}
                     </div>
                  </div>
               </div>

               <button
                  className="mb-0 btn btn-link fw-bold p-0 ms-2 text-uppercase d-flex flex-row justify-content-center align-items-center"
                  style={{
                     color: COLORS.blue,
                     fontSize: "0.65rem",
                     // letterSpacing: "1.1px",
                     textDecoration: "underline",
                  }}
                  onClick={() => {
                     navigate(`/bookingDetail/${booking._id}`, {
                        state: {
                           booking,
                        },
                     });
                  }}
               >
                  View More
                  <HiPlusSm class="me-2" style={{ fontSize: "0.8rem" }} />
               </button>
            </div>
            {/* DROPDOWN */}
            <div className="d-flex align-items-center fs-6">
               <div className="dropdown" style={{ height: 20, marginLeft: 10 }}>
                  <span
                     className="d-flex align-items-center justify-content-center"
                     style={{
                        width: 20,
                        height: 20,
                        padding: 0,
                        border: "none",
                     }}
                     type="button"
                     id="booking-actions"
                     data-bs-toggle="dropdown"
                     aria-expanded="false"
                  >
                     <BsThreeDotsVertical />
                  </span>
                  <ul
                     className="dropdown-menu"
                     aria-labelledby="booking-actions"
                  >
                     <li className="mb-1">
                        <button
                           className="dropdown-item pe-4"
                           onClick={() =>
                              confirmBookingActions("PAYMENT_DONE", booking._id)
                           }
                        >
                           <FaRupeeSign
                              color={COLORS.purple}
                              style={{ fontSize: "1.05rem" }}
                           />
                           <span className="ms-2">Payment Done</span>
                        </button>
                     </li>
                     <li className="mb-1">
                        <button
                           className="dropdown-item pe-4"
                           onClick={() =>
                              confirmBookingActions("EXEMPTED", booking._id)
                           }
                        >
                           <RiVipCrownFill
                              color={COLORS.blue}
                              style={{ fontSize: "1.05rem" }}
                           />
                           <span className="ms-2">Payment Exempted</span>
                        </button>
                     </li>
                     <li className="mb-1">
                        <button
                           className="dropdown-item pe-4"
                           onClick={() =>
                              confirmBookingActions(
                                 "MARK_COMPLETED",
                                 booking._id
                              )
                           }
                        >
                           <BsFillCheckCircleFill
                              color={COLORS.green}
                              style={{ fontSize: "1.05rem" }}
                           />
                           <span className="ms-2">Mark Completed</span>
                        </button>
                     </li>
                     <li className="mb-1">
                        <button
                           className="dropdown-item pe-4"
                           onClick={() =>
                              confirmBookingActions(
                                 "MARK_CANCELLED",
                                 booking._id
                              )
                           }
                        >
                           <MdCancel
                              color={COLORS.red}
                              style={{
                                 fontSize: "1.2rem",
                                 marginLeft: "-1px",
                              }}
                           />
                           <span className="ms-2">Mark Cancelled</span>
                        </button>
                     </li>
                  </ul>
               </div>
            </div>
         </div>

         {/* CARD BODY */}

         <div className="card-body px-3 pt-2 pb-0 d-flex flex-column justify-content-between">
            <div className="mt-2 top d-flex justify-content-between">
               <div className="left d-flex flex-column justify-content-start align-items-start pe-3">
                  <div className="customerInfo d-flex justify-content-center align-items-center">
                     {booking?.bookingMethod === "TICKET_BOOKING" &&
                     booking?.user?._id === "61d3db83457f59000497cdcc" ? (
                        <p className="fs-6 mb-0">
                           Ticket:{" "}
                           <span className="fs-6">{booking.ticketNumber}</span>
                        </p>
                     ) : (
                        <>
                           <FaUser style={{ fontSize: "0.85rem" }} />
                           <p
                              className="mb-0 ms-2"
                              style={{ fontSize: "0.85rem" }}
                           >
                              {cardData.customerInfo.name}{" "}
                              <span
                                 className="mb-0"
                                 style={{ fontSize: "0.85rem" }}
                              >
                                 {cardData.customerInfo.phone}
                              </span>
                           </p>
                        </>
                     )}
                  </div>
                  <div className="vehicleInfo d-flex justify-content-center align-items-center mt-1">
                     <FaCar style={{ fontSize: "0.9rem" }} />
                     <p className="mb-0 ms-2" style={{ fontSize: "0.85rem" }}>
                        {cardData.carNumber}
                     </p>
                  </div>
                  <div className="vehicleInfo d-flex flex-column flex-md-row justify-content-start align-items-start mt-1">
                     <div className="d-flex justify-content-center align-items-center">
                        <FaParking style={{ fontSize: "0.9rem" }} />
                        <p
                           className="mb-0 ms-2"
                           style={{ fontSize: "0.85rem" }}
                        >
                           {cardData.parkingInfo}
                        </p>
                     </div>
                     {/* <button
                        type="button"
                        class="btn badge rounded-pill bg-light text-primary text-decoration-underline mt-1 mt-md-0 ms-md-2 d-flex flex-row justify-content-center align-items-center"
                        style={{ fontSize: "0.7rem" }}
                     >
                        <HiOutlinePencil
                           class="me-1"
                           style={{ fontSize: "0.7rem" }}
                        />
                        Change Parking Info
                     </button> */}
                  </div>
                  <p
                     className="mt-2 text-muted"
                     style={{ fontSize: "0.65rem" }}
                  >
                     {cardData.createdTime}
                  </p>
               </div>
               <div
                  className="right d-flex flex-column justify-content-start align-items-center"
                  // style={{ border: "3px solid #efefef" }}
               >
                  {renderValetInfo(
                     booking.currentType,
                     booking.status,
                     booking.isMarkedAs,
                     cardData.pickupSelectedValets,
                     cardData.pickupValetInfo,
                     cardData.deliveryValetInfo
                  )}

                  {/* {renderValetInfo("", "", "", "", "", "")} */}
               </div>
            </div>

            <div className="bottom d-flex justify-content-between align-items-center mt-2">
               <div className="paymentStatus w-100 d-flex justify-content-start align-items-center mb-2">
                  {cardData.paymentStatus.icon}
                  <p
                     className="mb-0 ms-1"
                     style={{
                        fontSize: "0.7rem",
                        color: cardData.paymentStatus.color,
                     }}
                  >
                     {cardData.paymentStatus.text}
                  </p>
               </div>
               <div className="position-relative keyNumber w-100 d-flex align-items-center justify-content-end flex-column">
                  <span
                     style={{
                        position: "absolute",
                        width: "100%",
                        height: "3.6rem",
                        backgroundColor: "#f6f6f6",
                        bottom: 0,
                        right: 0,
                        display: "block",
                        borderRadius: "100px 100px 0 0 ",
                        zIndex: 5,
                     }}
                  ></span>
                  <p
                     className="mb-0"
                     style={{ fontSize: "0.65rem", zIndex: 10 }}
                  >
                     Key No.
                  </p>
                  <p
                     className="mb-0 fw-bold"
                     style={{ fontSize: "1.25rem", zIndex: 10 }}
                  >
                     {cardData.key}
                  </p>
               </div>
               <div className="bookingStatus w-100 ps-2 d-flex justify-content-end align-items-end mb-2">
                  <p
                     className="text-wrap text-end mb-0 fw-bold"
                     style={{
                        fontSize: "0.8rem",
                        color: cardData.bookingStatus.color,
                     }}
                  >
                     {cardData.bookingStatus.text}
                  </p>
               </div>
            </div>
         </div>
      </div>
   );
};

export default BookingCard;
