import React from "react";
import styled from "styled-components";
import { keyframes } from "styled-components";
import splashLogo from "../assets/splash-logo-new.png";

const Main = styled.div`
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;

	img {
		max-width: 150px;
		width: 35%;
		height: auto;
	}
`;

const loadingAnimation = keyframes`
   0% {
      transform: rotate(0)
   }

   100% {
      transform: rotate(360deg);
   }
`;

const Loader = styled.div`
	height: 40px;
	width: 40px;
	border-radius: 50%;
	border: 4px solid red;
	border-color: #eee #eee #eee #950000;
	transform: rotate(0);
	animation: ${loadingAnimation} 1s linear infinite;
`;

const SplashScreen = () => {
	return (
		<Main className="d-flex align-items-center flex-column justify-content-center">
			<img src={splashLogo} alt="ParkMate Logo" className="mb-4" />
			<Loader />
		</Main>
	);
};

export default SplashScreen;
