import axios from "axios";
import { format } from "date-fns";
import React, { useMemo } from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import dayjs from "dayjs";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import { IoClose } from "react-icons/io5";
import { API_URL, MAIN_API } from "./constants";
import styles from "./styles/BookingDetails.module.css";
import { toast } from "react-toastify";

function resolve(path, obj, separator = ".") {
   const properties = Array.isArray(path) ? path : path.split(separator);
   return properties.reduce((prev, curr) => prev && prev[curr], obj);
}

const Table = ({ title, fields, booking }) => {
   return (
      <table className="table table-hover table-bordered align-middle mb-4">
         <thead
            className="text-center"
            style={{ background: "rgba(0, 0, 0, 0.7)", color: "white" }}
         >
            <tr>
               <th
                  colspan="2"
                  className="display-6 p-2"
                  style={{ fontSize: "1.3rem", fontWeight: 400 }}
               >
                  {title}
               </th>
            </tr>
         </thead>
         <tbody>
            {fields.map((item) => {
               const fieldValue = resolve(item.field, booking);

               if (item.isImage) {
                  console.log({ fieldValue, x: booking[item.field] });
                  return (
                     <tr>
                        <td>{item.title}</td>
                        <td>
                           <img
                              style={{ height: "13rem", objectFit: "cover" }}
                              src={fieldValue}
                              alt={item.field}
                           />
                        </td>
                     </tr>
                  );
               }

               if (item.isBool) {
                  return (
                     <tr>
                        <td>{item.title}</td>
                        <td>{fieldValue ? item.values[1] : item.values[0]}</td>
                     </tr>
                  );
               }

               if (item.isDate) {
                  return (
                     <tr>
                        <td>{item.title}</td>
                        <td>
                           {format(
                              new Date(booking.createdAt),
                              "hh:mm aa | dd-MM-yy"
                           )}
                        </td>
                     </tr>
                  );
               }

               if (item.isCustom) {
                  if (
                     item.shouldRenderRow !== undefined &&
                     item.shouldRenderRow
                  ) {
                     return (
                        <tr>
                           <td>{item.title}</td>
                           <td>{item.customComponent(fieldValue, booking)}</td>
                        </tr>
                     );
                  } else {
                     return <></>;
                  }
               }

               return (
                  <tr>
                     <td>{item.title}</td>
                     <td>{fieldValue === "" ? "--" : fieldValue}</td>
                  </tr>
               );
            })}
         </tbody>
      </table>
   );
};

const BookingDetails = () => {
   const location = useLocation();
   const { booking } = location.state;
   const [bookingImages, setBookingImages] = useState({
      CAR_IMAGE: [],
      TICKET_IMAGE: [],
      KEY_IMAGE: [],
      CAR_NUMBER_IMAGE: [],
   });
   const [isLightboxOpen, setIsLightboxOpen] = useState(false);
   const [showValetModal, setShowValetModal] = useState(false);
   const imageSets = useMemo(
      () => ({
         carImage: "CAR_IMAGE",
         ticketImage: "TICKET_IMAGE",
         keyImage: "KEY_IMAGE",
         carNumberImage: "CAR_NUMBER_IMAGE",
      }),
      []
   );
   const [selectedImgSet, setSelectedImgSet] = useState(imageSets.keyImage); // TICKET_IMAGES, KEY_IMAGES, NUMBER_PLATE_IMAGES
   const [imageInfo, setImageInfo] = useState({
      first: "",
      second: "",
   });
   const [valetsList, setValetsList] = useState([]);
   const [isLoadingValets, setIsLoadingValets] = useState(false);
   const [selectedValet, setSelectedValet] = useState(null);
   const [valetType, setValetType] = useState("");
   const [showParkingInfo, setShowParkingInfo] = useState(false);
   const [isSubmittingParkingInfo, setIsSubmittingParkingInfo] =
      useState(false);
   const [parkingInfo, setParkingInfo] = useState(booking.parkingInfo || "");

   async function fetchValets(area) {
      setIsLoadingValets(true);
      const res = await axios.get(`${API_URL}/api/valets`, {
         params: {
            area,
         },
      });

      console.log(res.data);
      setValetsList(res.data.valets);
      setIsLoadingValets(false);
   }

   const fetchBookingImages = async (id) => {
      const { data } = await axios.get(`${MAIN_API}/api/order-images/`, {
         params: { orderId: id },
      });

      const imagesMap = {
         CAR_IMAGE: [],
         TICKET_IMAGE: [],
         KEY_IMAGE: [],
         CAR_NUMBER_IMAGE: [],
      };

      if (data) {
         data.orderImages.forEach((image) => {
            imagesMap[image.type].push(image);
         });

         setBookingImages(imagesMap);
      }
   };

   useEffect(() => {
      if (booking._id) {
         fetchBookingImages(booking._id);
      }
   }, [booking._id]);

   const openShowValetModal = (type) => {
      fetchValets(booking.area);
      setShowValetModal(true);
      setValetType(type);
   };

   const handleValetChange = async () => {
      if (!selectedValet) {
         toast.error("Select a valet");
         return;
      }

      // api call here
      try {
         const res = await axios.post(`${API_URL}/api/orders/change-valet`, {
            selectedValetId: selectedValet,
            orderId: booking._id,
            valetType,
         });
         const { data } = await res.data;

         console.log({ data });
         toast.info("Request sent to valet");
         setShowValetModal(false);
      } catch (error) {
         setShowValetModal(false);
         toast.error(error.message);
         console.log({ error });
      }
   };

   const selectedValetsList = () => {
      console.log({ selectedValets: booking.selectedValets });

      return (
         <ul
            style={{
               maxHeight: "120px",
               overflowY: "auto",
               paddingLeft: 24,
               marginBottom: 2,
            }}
         >
            {booking?.selectedValets?.map((valet) => (
               <li>{`${valet.firstname} ${valet.lastname}`}</li>
            ))}
         </ul>
      );
   };

   const bookingDetailsFields = [
      { title: "ID", field: "bookingId" },
      { title: "Ticket Number", field: "ticketNumber" },
      { title: "Key Number", field: "keyNumber" },
      { title: "Booked From", field: "source" },
      { title: "Booking Method", field: "bookingMethod" },
      { title: "Booking Area", field: "area" },
      { title: "Order Status", field: "status" },
      { title: "Booked At", field: "createdAt", isDate: true },
      {
         title: "Selected Valets",
         field: "selectedValets",
         isCustom: true,
         shouldRenderRow: true,
         customComponent: () => selectedValetsList(),
      },
      {
         title: "Parking Info",
         field: "parkingInfo",
         isCustom: true,
         shouldRenderRow: true,
         customComponent: (fieldValue, booking) => {
            return (
               <div>
                  <p className="mb-2">{fieldValue}</p>
                  <button
                     className="btn btn-outline-primary btn-sm"
                     type="button"
                     onClick={() => setShowParkingInfo(true)}
                  >
                     Edit
                  </button>
               </div>
            );
         },
      },
   ];

   const customerDetailsFields = [
      { title: "Customer Name", field: "user.firstname" },
      { title: "Customer Number", field: "user.phonenumber" },
      { title: "Customer Location", field: "userLocation" },
   ];

   const vehicleFields = [
      { title: "Vehicle Number", field: "vehicle.plateInfo.plateNumber" },
      { title: "Vehicle Type", field: "vehicle.vehicleInfo.type" },
      { title: "Vehicle Model", field: "vehicle.modelMake.model" },
      { title: "Vehicle Make", field: "vehicle.modelMake.make" },
      { title: "Vehicle Colour", field: "vehicle.color.color" },
      {
         title: "Vehicle Orientation",
         field: "vehicle.orientation.orientation",
      },
   ];

   const paymentDetailsFields = [
      {
         title: "Payment Status",
         field: "paymentStatus",
         isBool: true,
         values: ["Pending", "Paid"],
      },
      { title: "Payment Mode", field: "paymentType" },
      { title: "Amount", field: "parkingCharges" },
   ];

   const pickupDetailsFields = [
      {
         title: "Pickup Valet",
         field: "pickupValet.firstname",
         isCustom: true,
         shouldRenderRow: true,
         customComponent: (fieldValue, booking) => {
            return (
               <div className="d-flex align-items-center">
                  <p className="mb-0">{fieldValue}</p>
                  {booking.currentType === "PICKUP" && (
                     <button
                        className="btn btn-outline-primary btn-sm ms-3"
                        type="button"
                        onClick={() => openShowValetModal("PICKUP")}
                     >
                        Change
                     </button>
                  )}
               </div>
            );
         },
      },
      { title: "Valet Number", field: "pickupValet.phonenumber" },
      { title: "Pickup Address", field: "pickupAddress" },
   ];

   const deliveryDetailsFields = [
      {
         title: "Delivery Valet",
         field: "deliveryValet.firstname",
         isCustom: true,
         shouldRenderRow: true,
         customComponent: (fieldValue, booking) => {
            return (
               <div className="d-flex align-items-center">
                  <p className="mb-0">{fieldValue}</p>
                  {!fieldValue || booking.status === "COMPLETED" ? (
                     ""
                  ) : (
                     <button
                        onClick={() => openShowValetModal("DELIVERY")}
                        className="btn btn-outline-primary btn-sm ms-3"
                     >
                        Change
                     </button>
                  )}
               </div>
            );
         },
      },
      { title: "Valet Number", field: "deliveryValet.phonenumber" },
      { title: "Delivery Address", field: "deliveryAddress" },
   ];

   const parkingDetailsFields = [
      { title: "Parking Name", field: "parkingName" },
      {
         title: "Car Images",
         field: "",
         isCustom: true,
         shouldRenderRow: bookingImages[imageSets.carImage].length > 0,
         customComponent: () => [
            <button
               className="btn btn-outline-primary btn-sm"
               onClick={() => {
                  setSelectedImgSet(imageSets.carImage);
                  setIsLightboxOpen(true);
                  setImageInfo({
                     first: bookingImages[imageSets.carImage][0]?.status,
                     second: bookingImages[imageSets.carImage][0]?.createdAt,
                  });
               }}
            >
               View Images
            </button>,
         ],
      },
      {
         title: "Ticket Images",
         field: "",
         isCustom: true,
         shouldRenderRow: bookingImages[imageSets.ticketImage].length > 0,
         customComponent: () => [
            <button
               className="btn btn-outline-primary btn-sm"
               onClick={() => {
                  setSelectedImgSet(imageSets.ticketImage);
                  setIsLightboxOpen(true);
                  setImageInfo({
                     first: bookingImages[imageSets.ticketImage][0]?.status,
                     second: bookingImages[imageSets.ticketImage][0]?.createdAt,
                  });
               }}
            >
               View Images
            </button>,
         ],
      },
      {
         title: "Key Images",
         field: "",
         isCustom: true,
         shouldRenderRow: bookingImages[imageSets.keyImage].length > 0,
         customComponent: () => [
            <button
               className="btn btn-outline-primary btn-sm"
               onClick={() => {
                  setSelectedImgSet(imageSets.keyImage);
                  setIsLightboxOpen(true);
                  setImageInfo({
                     first: bookingImages[imageSets.keyImage][0]?.status,
                     second: bookingImages[imageSets.keyImage][0]?.createdAt,
                  });
               }}
            >
               View Images
            </button>,
         ],
      },
      {
         title: "Number Plate Image",
         field: "",
         isCustom: true,
         shouldRenderRow: bookingImages[imageSets.carNumberImage].length > 0,
         customComponent: () => [
            <button
               className="btn btn-outline-primary btn-sm"
               onClick={() => {
                  setSelectedImgSet(imageSets.carNumberImage);
                  setIsLightboxOpen(true);
                  setImageInfo({
                     first: bookingImages[imageSets.carNumberImage][0]?.status,
                     second:
                        bookingImages[imageSets.carNumberImage][0]?.createdAt,
                  });
               }}
            >
               View Images
            </button>,
         ],
      },
   ];

   const submitParkingInfo = async () => {
      try {
         setIsSubmittingParkingInfo(true);
         const res = await axios.post(`${API_URL}/api/orders/parking-info`, {
            parkingInfo,
            orderId: booking._id,
         });
         const data = await res.data;

         setIsSubmittingParkingInfo(false);
         setShowParkingInfo(false);
         console.log({ data });
         toast.success("success");
      } catch (error) {
         setIsSubmittingParkingInfo(false);
         setShowParkingInfo(false);

         toast.error(error.msg);
      }
   };

   console.log({
      bookingImages,
      selectedImgSet,
   });

   return (
      <>
         <div className="container">
            <h2 className="text-center my-4">Booking Info</h2>
            {isLightboxOpen && (
               <div
                  style={{
                     position: "fixed",
                     top: 0,
                     left: 0,
                     right: 0,
                     bottom: 0,
                     width: "100vw",
                     height: "100vh",
                     background: "rgba(0, 0, 0, 0.9)",
                     display: "flex",
                     alignItems: "center",
                  }}
                  // className="fixed inset-0 w-screen h-screen bg-gray-900/90 flex align-center"
               >
                  <div
                     style={{
                        position: "absolute",
                        bottom: 2,
                        left: 0,
                        width: "100%",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                     }}
                  >
                     <div
                        style={{
                           fontSize: "12px",
                           color: "white",
                           marginLeft: 20,
                        }}
                     >
                        <p style={{ marginBottom: 2 }}>{imageInfo.first}</p>
                        <p style={{ marginBottom: 0 }}>
                           {dayjs(imageInfo.second).format("hh:mm A DD/MM/YY")}
                        </p>
                     </div>
                     <button
                        onClick={() => setIsLightboxOpen(false)}
                        style={{
                           background: "transparent",
                           border: "none",
                           fontSize: 24,
                           padding: 4,
                           marginRight: 26,
                        }}
                     >
                        <IoClose color="#fff" />
                     </button>
                  </div>
                  <Carousel
                     className={styles.carouselStyles}
                     width="100%"
                     swipeable
                     infiniteLoop
                     showArrows
                     autoFocus
                     showIndicators={false}
                     showThumbs
                     thumbWidth={40}
                     onChange={(i, item) => {
                        setImageInfo({
                           first: item.props["data-status"],
                           second: item.props["data-time"],
                        });
                     }}
                  >
                     {bookingImages[selectedImgSet]?.map((image) => (
                        <img
                           src={image.url}
                           key={image._id}
                           style={{
                              objectFit: "contain",
                              width: "100%",
                              height: "100%",
                           }}
                           alt={
                              image.status +
                              " " +
                              dayjs(image.createdAt).format("hh:mm A DD/MM/YY")
                           }
                        />
                     ))}
                  </Carousel>
               </div>
            )}

            <div>
               <Table
                  title="Booking Details"
                  fields={bookingDetailsFields}
                  booking={booking}
               />
               <Table
                  title="Customer Details"
                  fields={customerDetailsFields}
                  booking={booking}
               />
               {booking.vehicle ? (
                  <Table
                     title="Vehicle Details"
                     fields={vehicleFields}
                     booking={booking}
                  />
               ) : (
                  ""
               )}
               <Table
                  title="Payment Details"
                  fields={paymentDetailsFields}
                  booking={booking}
               />
               <Table
                  title="Pickup Details"
                  fields={pickupDetailsFields}
                  booking={booking}
               />
               <Table
                  title="Delivery Details"
                  fields={deliveryDetailsFields}
                  booking={booking}
               />
               <Table
                  title="Parking Details"
                  fields={parkingDetailsFields}
                  booking={booking}
               />
            </div>
         </div>

         <div
            className={`modal  ${showValetModal ? "d-block" : "d-none"}`}
            id="changeValetModal"
         >
            <div className="modal-dialog modal-fullscreen">
               <div className="modal-content">
                  <div className="modal-header">
                     <h5
                        className="modal-title"
                        id="exampleModalLabel"
                        style={{ textTransform: "capitalize" }}
                     >
                        Select {valetType.toLowerCase()} Valet
                     </h5>
                     <button
                        type="button"
                        className="btn-close"
                        data-bs-dismiss="modal"
                        onClick={() => setShowValetModal(false)}
                     ></button>
                  </div>
                  <div className="modal-body">
                     {isLoadingValets ? (
                        <div class="d-flex justify-content-center">
                           <div
                              class="spinner-border text-primary"
                              role="status"
                           >
                              <span class="visually-hidden">Loading...</span>
                           </div>
                        </div>
                     ) : (
                        <ul className="list-group">
                           {valetsList.map((v) => (
                              <li
                                 key={v._id}
                                 className="list-group-item cursor-pointer list-group-item-action"
                                 onClick={() => {
                                    setSelectedValet(v._id);
                                 }}
                              >
                                 <input
                                    class="form-check-input me-1"
                                    type="radio"
                                    id={v._id}
                                    value={v._id}
                                    name="select-valet"
                                 />
                                 <label htmlFor={v._id} className="ms-1">
                                    {v.firstname + " " + v.lastname}{" "}
                                    {v.phonenumber}
                                 </label>
                              </li>
                           ))}
                        </ul>
                     )}
                  </div>
                  <div className="modal-footer">
                     <button
                        type="button"
                        className="btn btn-secondary"
                        data-bs-dismiss="modal"
                        onClick={() => setShowValetModal(false)}
                     >
                        Close
                     </button>
                     <button
                        type="button"
                        className="btn btn-primary"
                        onClick={handleValetChange}
                     >
                        Save changes
                     </button>
                  </div>
               </div>
            </div>
         </div>

         <div
            class="modal"
            style={{
               background: "rgba(0, 0, 0, 0.4)",
               display: showParkingInfo ? "block" : "none",
            }}
         >
            <div class="modal-dialog modal-dialog-centered">
               <div class="modal-content">
                  <div class="modal-header">
                     <h5 class="modal-title">Edit Parking Info</h5>
                     <button
                        type="button"
                        onClick={() => setShowParkingInfo(false)}
                        class="btn-close"
                     ></button>
                  </div>
                  <div class="modal-body">
                     <textarea
                        value={parkingInfo}
                        onChange={(e) => setParkingInfo(e.target.value)}
                        style={{
                           width: "100%",
                           height: 80,
                           borderRadius: 6,
                           borderColor: "rgba(0, 0, 0, 0.2)",
                           padding: "4px 8px",
                           resize: "none",
                        }}
                        placeholder="Enter parking info here..."
                     ></textarea>
                  </div>
                  <div class="modal-footer">
                     <button
                        onClick={() => setShowParkingInfo(false)}
                        type="button"
                        class="btn btn-secondary"
                        data-bs-dismiss="modal"
                     >
                        Close
                     </button>
                     <button
                        type="button"
                        class="btn btn-primary"
                        onClick={submitParkingInfo}
                        disabled={isSubmittingParkingInfo}
                     >
                        {isSubmittingParkingInfo ? "Submitting..." : "Submit"}
                     </button>
                  </div>
               </div>
            </div>
         </div>
      </>
   );
};

export default BookingDetails;
